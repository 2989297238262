<template>
<v-container fluid :height="(ancho < 517)?'100%':'auto'" :style="(ancho < 517)?'height:100%;padding:0':''">
    <v-layout wrap align-center justify-center :style="(ancho < 517)?'height:100%':''">

        <v-form @submit.prevent="agregar">
            <v-card :width="(ancho < 517)?'auto':'900'" :style="'background-image: linear-gradient(to top,rgba(69, 171, 202),rgba(14, 87, 156));border-color: rgb(249, 249, 249);'" flat raised color="#F9F9F9">
                <v-row align="center" justify="center">
                    <v-col cols="auto" md="12">
                        <v-card-title>
                            <span class="headline white--text">Activar cuenta del paciente</span>
                        </v-card-title>

                        <v-divider></v-divider>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="12" md="12" class="white--text">
                                        Datos del paciente
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3">
                                        <v-text-field dark rounded filled v-on:keyup="$data.form.tblConfPersonasnaturales[0].copnNombre1 = $event.target.value.toUpperCase()" :error="$v.form.tblConfPersonasnaturales.$each[0].copnNombre1.$error" :rules="nameRules" label="Primer Nombre" v-model="form.tblConfPersonasnaturales[0].copnNombre1"></v-text-field>
                                    </v-col>
                                    <!-- <v-col cols="12" sm="12" md="3">
                                        <v-text-field dark rounded filled v-on:keyup="$data.form.tblConfPersonasnaturales[0].copnNombre2= $event.target.value.toUpperCase()" label="Segundo Nombre" v-model="form.tblConfPersonasnaturales[0].copnNombre2"></v-text-field>
                                    </v-col> -->
                                    <v-col cols="12" sm="12" md="3">
                                        <v-text-field dark rounded filled v-on:keyup="$data.form.tblConfPersonasnaturales[0].copnApellido1 = $event.target.value.toUpperCase()" :rules="nameRules" :error="$v.form.tblConfPersonasnaturales.$each[0].copnApellido1.$error" label="Primer Apellido" v-model="form.tblConfPersonasnaturales[0].copnApellido1"></v-text-field>
                                    </v-col>
                                    <!-- <v-col cols="12" sm="12" md="3">
                                        <v-text-field dark rounded filled v-on:keyup="$data.form.tblConfPersonasnaturales[0].copnApellido2 = $event.target.value.toUpperCase()" label="Segundo Apellido" v-model="form.tblConfPersonasnaturales[0].copnApellido2"></v-text-field>
                                    </v-col> -->
                                    <v-col cols="12" sm="12" md="4">
                                        <v-text-field dark rounded filled v-on:keyup="$data.form.copeIdentificacion = $event.target.value.toUpperCase()" :rules="nameRules" label="Identificacion" :error="$v.form.copeIdentificacion.$error" v-model="form.copeIdentificacion"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <v-autocomplete dark rounded filled :rules="nameRules" v-model="form.copeTipoIdentificacion" :error="$v.form.copeTipoIdentificacion.$error" :items="tipoidentificacion" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de identificacion" clearable></v-autocomplete>
                                    </v-col>
                                    <!-- <v-col cols="12" sm="12" md="4">
                                        <v-text-field dark rounded filled v-on:keyup="$data.form.copeTelefono = $event.target.value.toUpperCase()" :rules="nameRules" label="Telefono" v-model="form.copeTelefono"></v-text-field>
                                    </v-col> -->
                                    <v-col cols="12" sm="12" md="4">
                                        <v-text-field dark rounded filled v-on:keyup="$data.form.copeEmail = $event.target.value.toUpperCase()" :rules="nameRules" label="Email" v-model="form.copeEmail"></v-text-field>
                                    </v-col>
                                    <!-- <v-col cols="12" sm="12" md="4">
                                        <v-text-field dark rounded filled v-on:keyup="$data.form.copeDireccion = $event.target.value.toUpperCase()" :rules="nameRules" label="Direccion" v-model="form.copeDireccion"></v-text-field>
                                    </v-col> -->
                                    <v-col cols="12" sm="12" md="4">
                                        <v-autocomplete dark rounded filled v-model="form.copeTipoPais" :rules="nameRules" :error="$v.form.copeTipoPais.$error" :items="tipopais" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Pais" clearable @change="getDepa()"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" v-if="form.copeTipoPais">
                                        <v-autocomplete dark rounded filled v-model="form.copeTipoDepartamento" :rules="nameRules" :error="$v.form.copeTipoDepartamento.$error" :items="tipodepartamento2" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Departamento" clearable @change="getMuni()"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" v-if="form.copeTipoDepartamento">
                                        <v-autocomplete dark rounded filled v-model="form.copeTipoMunicipio" :rules="nameRules" :error="$v.form.copeTipoMunicipio.$error" :items="tipomunicipio2" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Municipio" clearable></v-autocomplete>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="3">
                                        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field :rules="nameRules" dark rounded filled v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento" label="Fecha de nacimiento" append-icon="event" v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker locale="ES" v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento" :error="$v.form.tblConfPersonasnaturales.$each[0].tblConfPersonaspacientes.$each[0].coppFechanacimiento.$error" @input="menu = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3">
                                        <v-autocomplete dark rounded filled :rules="nameRules" v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoSexo" :items="tiposexo" :error="$v.form.tblConfPersonasnaturales.$each[0].tblConfPersonaspacientes.$each[0].coppTipoSexo.$error" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Sexo" clearable></v-autocomplete>
                                    </v-col>
                                    <!-- <v-col cols="12" sm="12" md="3">
                                        <v-autocomplete dark rounded filled  :rules="nameRules" v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoPaisnacieminto" :items="tipopaisn" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Pais de nacimiento" clearable @change="getDepan()"></v-autocomplete>

                                    </v-col>

                                    <v-col cols="12" sm="12" md="3" v-if="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoPaisnacieminto">
                                        <v-autocomplete dark rounded filled  :rules="nameRules" v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoDepartamentonacimiento" :items="tipodepartamenton" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Departamento de nacimiento" clearable @change="getMunin()"></v-autocomplete>

                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" v-if="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoDepartamentonacimiento">
                                        <v-autocomplete dark rounded filled  :rules="nameRules" v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoMunicipionacimiento" :items="tipomunicipion" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Municipio de nacimiento" clearable></v-autocomplete>

                                    </v-col>
                                    <v-col cols="12" sm="12" md="3">
                                        <v-text-field dark rounded filled  :rules="nameRules" v-on:keyup="$data.form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoBarrio = $event.target.value.toUpperCase()" label="Barrio" v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoBarrio"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="3">
                                        <v-autocomplete dark rounded filled  :rules="nameRules" v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoZona" :items="tipozona" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Zona" clearable></v-autocomplete>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="3">
                                        <v-autocomplete dark rounded filled  :rules="nameRules" v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoPoblacion" :items="tipopoblacion" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Poblacion" clearable></v-autocomplete>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="3">
                                        <v-autocomplete dark rounded filled  :rules="nameRules" v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoNiveleducativo" :items="tiponvleducacion" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Nivel educativo" clearable></v-autocomplete>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="3">
                                        <v-autocomplete dark rounded filled  :rules="nameRules" v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoOcupacion" :items="tipoocupacion" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Ocupacion" clearable></v-autocomplete>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="3">
                                        <v-autocomplete dark rounded filled  :rules="nameRules" v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoEstado" :items="tipoestado" :error="$v.form.tblConfPersonasnaturales.$each[0].tblConfPersonaspacientes.$each[0].coppTipoEstado.$error" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Estado" clearable></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3">
                                        <v-autocomplete dark rounded filled  :rules="nameRules" v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoEstadocivil" :items="tipoestadocivil" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Estado civil" clearable></v-autocomplete>
                                    </v-col> -->
                                </v-row>
                                <v-divider></v-divider>
                                <v-row>

                                    <v-col cols="12" sm="12" md="4">
                                        <v-text-field type="password" v-model="form.tblAuthUser.password_hash" label="password" dark rounded filled :rules="(isNew)?password:[]" required />
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                        <v-text-field type="password" v-model="form.tblAuthUser.repassword" label="re password" dark rounded filled :rules="(isNew)?repassword:[]" required />
                                    </v-col>

                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <div class="flex-grow-1"></div>
                            <v-btn v-if="!copeId && !nuevo" color="white darken-1" text to="/" @click="setRegistro(false)">Cancelar</v-btn>
                            <v-btn color="white darken-1" @click="agregar">Guardar</v-btn>
                        </v-card-actions>
                    </v-col>
                </v-row>
            </v-card>
        </v-form>

    </v-layout>
</v-container>
</template>

<script>
import {
    required
}
from 'vuelidate/lib/validators'
export default {
    name: 'Createpaciente',
    props: {
        copeId: Number,
        nuevo: Boolean,
        setRegistro: Function,
    },
    data() {
        return {
            menu: false,
            isNew: true,
            url: "sistema/personas/",
            token: this.$cookies.get("token"),
            
            nameRules: [v => !!v || "Campo requirido", ],
            password: [
                v => !!v || "requiredo",
                v =>
                (v && v.length > 2) || "La contraseña debe tener almenos 4 digitos"
            ],
            repassword: [
                v => !!v || "requiredo",
                v =>
                (v && v.length > 2) || "La contraseña debe tener almenos 4 digitos"
            ],
            ancho: (typeof window !== 'undefined') ? window.outerWidth : 517,

            form: {
                copeEmail: null,

                copeIdentificacion: null,

                copeTipoIdentificacion: null,

                copeTipoPais: null,

                copeTipoDepartamento: null,

                copeTipoMunicipio: null,
                tblAuthUser: {
                    username: "",
                    email: "",
                    role: "PACIENTE",
                    status: 1,
                    password_hash: "",
                    repassword: ""
                },
                tblConfPersonasnaturales: [{
                    copnNombre1: null,
                    copnApellido1: null,
                    tblConfPersonaspacientes: [{
                        coppFechanacimiento: new Date().yyyymmdd(),
                        coppTipoSexo: null,
                        coppTipoEstado: null,
                    }]
                }],

            },
            tipoidentificacion: [],
            tipodepartamento: [],
            tipomunicipio: [],
            tipodepartamento2: [],
            tipomunicipio2: [],
            tipopais: [],
            tipopaisn: [],
            tipoestado: [],
            tipozona: [],
            tipopoblacion: [],
            tiponvleducacion: [],
            tipoocupacion: [],
            tiposexo: [],
            tipoestadocivil: [],

        }
    },
    validations: {

        form: {

            copeIdentificacion: {
                required,
            },

            copeTipoIdentificacion: {
                required,
            },

            copeTipoPais: {
                required,
            },

            copeTipoDepartamento: {
                required,
            },

            copeTipoMunicipio: {
                required,
            },
            tblAuthUser: {
                username: {
                    required,
                },
               
                role: {
                    required,
                },
                password_hash: {
                    required,
                },
                repassword: {
                    required,
                },
            },
            tblConfPersonasnaturales: {
                $each: {
                    copnNombre1: {
                        required,
                    },
                    copnApellido1: {
                        required,
                    },
                    tblConfPersonaspacientes: {
                        $each: {
                            coppFechanacimiento: {
                                required,
                            },
                            /*  coppTipoPaisnacieminto: {
                                  required,
                              },
                              coppTipoDepartamentonacimiento: {
                                  required,
                              },
                              coppTipoMunicipionacimiento: {
                                  required,
                              },*/
                            coppTipoSexo: {
                                required,
                            },
                            coppTipoEstado: {
                                required,
                            },

                        }

                    },

                }
            },

        },
    },
    created: async function () {
        
        let tipos = await this.$apiService.index("sistema/maestra/indexregistro").then(data => data);
        this.tipoidentificacion = tipos.tipoidentificacion;
        this.tipopais = tipos.tipopais;
        this.tipoestado = tipos.tipoestado;
        this.tipozona = tipos.tipozona;
        this.tipopoblacion = tipos.tipopoblacion;
        this.tiponvleducacion = tipos.tiponvleducacion;
        this.tipoocupacion = tipos.tipoocupacion;
        this.tiposexo = tipos.tiposexo;
        this.tipoestadocivil = tipos.tipoestadocivil;
        this.tipodepartamento = tipos.tipodepartamento;
        this.tipomunicipio = tipos.tipomunicipio;
        this.form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoEstado = this.tipoestado.find(e => e.comaNombrecorto == 'ACTIVO').comaId;
        this.limpiar();
        if (this.$route.params.id) {
            await this.editar(this.$route.params.id);
        }
        if (this.copeId) {
            await this.editar(this.copeId);
        }
    },
    computed: {
        formTitle() {
            return this.isNew === true ? "Registrar nuevo" : "Actualizar";
        },

    },

    methods: {

        async getDepa() {
            this.tipodepartamento2 = this.tipodepartamento.filter(e => e.comaDependencia == this.form.copeTipoPais);
        },
        async getMuni() {
            this.tipomunicipio2 = this.tipomunicipio.filter(e => e.comaDependencia == this.form.copeTipoDepartamento);
        },
        async editar(id) {
            try {

                this.form = await this.$apiService.index(this.url + "view/" + id + "/pacientes").then(data => data);
                await this.getDepa();
                await this.getMuni();

                this.isNew = false;

            } catch (error) {
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },
        async agregar() {

            this.form.tblAuthUser.username = this.form.copeIdentificacion;
            this.form.tblAuthUser.email = this.form.copeEmail;
            this.$v.form.$touch();
            if (!this.$v.form.$error) {
                try {
                    if (this.isNew) {

                        let data = await this.$apiService.create(this.url + "activarpaciente", this.form).then(data => data);
                        if (data) {
                            if (data.error) {
                                this.$swal.fire({
                                    title: data.error,
                                    text: "si tienes algun problema con tu cuenta contacta con el administrador",
                                    icon: "error"
                                });
                            } else {
                                if (this.nuevo) {
                                    this.$emit('validar', { tipo: 'nuevo', paciente: data });
                                } else {
                                    this.$swal.fire({
                                        title: "Completado!",
                                        text: "Registro exitoso",
                                        icon: "success",
                                        confirmButtonText: "Ok"
                                    });
                                    this.setRegistro(false);
                                    this.$router.push({ path: '/', params: { login: true } });
                                }
                            }

                        }

                    }
                } catch (error) {
                    console.log(error);
                    this.$swal.fire({
                        title: "Algo sucedio!",
                        text: "contacta con el administrador",
                        icon: "error"
                    });
                }
            }

        },
        limpiar() {
            this.form = {

                copeEmail: null,

                copeIdentificacion: null,

                copeTipoIdentificacion: null,

                copeTipoPais: null,

                copeTipoDepartamento: null,

                copeTipoMunicipio: null,
                tblAuthUser: {
                    username: "",
                    email: "",
                    role: "PACIENTE",
                    status: 1,
                    password_hash: "",
                    repassword: ""
                },
                tblConfPersonasnaturales: [{
                    copnNombre1: null,
                    copnApellido1: null,
                    tblConfPersonaspacientes: [{
                        coppFechanacimiento: new Date().yyyymmdd(),
                        coppTipoSexo: null,
                        coppTipoEstado:  this.tipoestado.find(e => e.comaNombrecorto == 'ACTIVO').comaId,
                    }]
                }],

            };
        },
    }

}
</script>
