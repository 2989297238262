<template>
    <v-app>
        <main style="height:100%">
            <v-layout  wrap align-center justify-center :style="(ancho < 517) ? 'height:100%' : ''">
<v-container fluid class="cyan" height="auto">
    <v-row align="center" justify="center">

        <v-col cols="12" sm="12" md="10">
            <span class="white--text display-2 text-center">ATENCIONES</span>
        </v-col>
        <v-col cols="12" sm="12" md="10">
            <v-card class="" width="100%" flat raised color="#F9F9F9">
                <v-card-title>
                    <v-btn @click="logout">salir</v-btn>
                </v-card-title>
                <button type="button" :ref="'sonarvoz'"></button>
                <v-card-text>
                    <v-row no-gutters v-if="lista.length > 0 || pasados.length > 0">

                        <v-col cols="12" sm="12" md="4" style="border: 1px solid #dbd7d7;">
                            <span class="green--text display-2 text-center">TURNOS PERDIDOS
                            </span>
                            <v-container>
                                    <v-row  v-for="(item,index) in pasados" :key="index+'pasado'">
                                        <v-col cols="12" sm="12" md="8">
                                            <span class="dark--text headline">PROFESIONAL: {{item.empleado}}</span><br>
                                            <span class="dark--text headline">PACIENTE: {{item.paciente}}</span>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <span class="primary--text headline">TURNO {{item.turno}} </span>
                                            <!--<span class="primary--text display-2">DE {{item.turnos}}</span>-->
                                        </v-col>
                                    </v-row>
                            </v-container>
                        </v-col>

                        <v-col cols="12" sm="12" md="8" style="border: 1px solid #dbd7d7;">
                            <span class="green--text display-2 text-center">TURNOS</span>
                            <v-container>
                                    <v-row v-for="(item,index) in lista" :key="index+'t1'" :class="(item.actual == item.cifa_id)?'teal lighten-3':''">
                                        <v-col cols="12" sm="12" md="9">
                                            <span class="dark--text display-2">{{item.cargo}}: {{item.empleado}}</span><br>
                                            <span class="dark--text display-2">PACIENTE: {{item.paciente}}</span>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="3">
                                            <span class="primary--text display-2">TURNO {{item.turno}} </span>
                                            <!--<span class="primary--text display-2">DE {{item.turnos}}</span>-->
                                        </v-col>

                                    </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-else>
                        <span class="primary--text display-4 text-center">NO TENEMOS ATENCIONES PARA HOY</span>
                    </v-row>
                </v-card-text>

            </v-card>
        </v-col>

    </v-row>
</v-container>
</v-layout></main></v-app>
</template>

<script>
//import sonido from '@/assets/files/Elevator Ding-SoundBible.com-685385892.mp3';

export default {
    name: "turno",
    data() {
        return {
            user: this.$cookies.get("user"),
            token: this.$cookies.get("token"),
            
            lista: [],
            pasados: [],
            actuales: [],
            synth: (window) ? speechSynthesis : null,
            voiceList: [],
            greetingSpeech: (window) ? new SpeechSynthesisUtterance() : null,
            texto: '',
            anteriores: []
        }
    },
    created: async function () {
        
        

        this.lista = await this.$apiService.index(`cita/facturaciones/turnos?fecha=${new Date().yyyymmdd()}`).then(data => data);

        this.pasados = await this.$apiService.index(`cita/facturaciones/turnospasados?fecha=${new Date().yyyymmdd()}`).then(data => data);
        //speechSynthesis.onvoiceschanged = () => {

        // }
    },
    sockets: {
        cambiarturnos: async function (data) {

            if (data.indexOf('historia') > -1) {
                this.lista = await this.$apiService.index(`cita/facturaciones/turnos?fecha=${new Date().yyyymmdd()}`).then(data => data);
                this.pasados = await this.$apiService.index(`cita/facturaciones/turnospasados?fecha=${new Date().yyyymmdd()}`).then(data => data);
                this.lista.filter(e => e.actual != null).forEach((element) => {
                    if (this.anteriores.find(e => e.cifa_id == element.cifa_id) == undefined) {
                        this.playSound('/files/Elevator Ding-SoundBible.com-685385892.mp3');
                        this.texto = element.paciente + ',TURNO ' + element.turno + ',con el '+element.cargo+' ' + element.empleado;
                        this.$refs.sonarvoz.click();
                    } else {
                        if (this.anteriores.find(e => e.actual == null && e.cifa_id == element.cifa_id) != undefined) {
                            this.playSound('/files/Elevator Ding-SoundBible.com-685385892.mp3');
                            this.texto = element.paciente + ',TURNO ' + element.turno + ',con el '+element.cargo+' ' + element.empleado;
                            this.$refs.sonarvoz.click();
                        }
                    }

                });
                this.anteriores = this.lista;
            } else {
                this.lista = await this.$apiService.index(`cita/facturaciones/turnos?fecha=${new Date().yyyymmdd()}`).then(data => data);
                this.pasados = await this.$apiService.index(`cita/facturaciones/turnospasados?fecha=${new Date().yyyymmdd()}`).then(data => data);

            }

        },

    },
    mounted() {
        if (window) {
            this.voiceList = this.synth.getVoices()

            this.synth.onvoiceschanged = () => {
                this.voiceList = this.synth.getVoices()

            }
            this.$refs.sonarvoz.addEventListener('click', () => {
                this.playvoz();
            });
        }

    },
    methods: {
        playvoz() {
            this.greetingSpeech.text = this.texto;
            this.greetingSpeech.voice = this.voiceList.find(e => e.name == 'Microsoft Helena Desktop - Spanish (Spain)');
            this.greetingSpeech.pitch = 1.5;
            this.greetingSpeech.rate = 0.6;
            this.synth.speak(this.greetingSpeech);
        },
        playSound(sound) {
            if (sound) {
                var audio = new Audio(sound);
                audio.play();
            }
        },
        logout: function () {
            this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie));
           this.$router.push("login");
        }
    },

}
</script>

<style>
.login-card {
    margin-top: 2%px !important;
}
</style>
