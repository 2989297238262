<template>
    <v-app>
        <main style="height:100%">
            <v-layout wrap align-center justify-center :style="(ancho < 517) ? 'height:100%' : ''">
                <v-card :class="(ancho < 517) ? '' : 'login-card rounded-xl'" elevation="0" height="100%" :width="(ancho < 517) ? '100%' : '500'" flat raised>
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="12">
                            <v-card-title :class="'primary--text'" align-center>
                                <span class="subtitle-1">INICIO DE SESION IPSTOOLS SISTEMA INTEGRAL</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text v-if="!(ancho < 517)" align="center" justify="center">
                                <v-img style="border-radius: 50%;box-shadow: 3px 5px 5px #9cb2c5;margin: 5px;" :src="require('@/assets/ips tools fondo blanco.png')" contain aspect-ratio="1" max-width="200" max-height="200"></v-img>
                            </v-card-text>
                            <v-card-text v-else align="center" justify="center">
                                <v-img :src="require('@/assets/ips tools fondo blanco.png')" aspect-ratio="1" contain max-width="100" max-height="100"></v-img>
                            </v-card-text>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-form ref="form" v-model="valid" align-center @submit.prevent="login" lazy-validation>
                                <v-card-text v-if="loading">
                                    <v-layout row fill-height justify-center align-center>
                                        <v-progress-circular :size="90" color="primary" indeterminate />
                                    </v-layout>
                                </v-card-text>
                                <v-card-text v-else>
                                    <v-row>
                                        <v-col class="py-0" cols="12" sm="12" md="12">
                                            <v-autocomplete dense rounded filled v-model="credentials.entidad" :items="ips" item-text="NOMBRE" item-value="VERSION" label="Entidad" :search-input.sync="search" :error-messages="error" @keyup="filtrarIps()" :rules="rules.entidad" required hide-no-data hide-selected  clearable> </v-autocomplete>
                                        </v-col>
                                        <v-col class="py-0" cols="12" sm="12" md="6">
                                            <v-text-field dense v-model="credentials.username" rounded filled label="username" :rules="rules.username" required />
                                        </v-col>
                                        <v-col class="py-0" cols="12" sm="12" md="6">
                                            <v-text-field dense type="password" rounded filled v-model="credentials.password" label="password" @keypress.enter="login" :rules="rules.password" required />
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-divider></v-divider>
                                <div class="text-center">
                                    <v-btn v-if="(!ancho < 517)" large outlined color="primary" class="my-2" :disabled="!valid" @click="login">Iniciar</v-btn>
                                    <v-btn v-else tiled large color="primary" class="my-2" :disabled="!valid" @click="login">Iniciar</v-btn>
                                </div>
                                <!-- <v-divider></v-divider> -->
                                <div class="text-center primary--text">
                                    Desarrollado por <a style="text-decoration: none;color: black;" href="http://www.jasoftwaresas.com/" target="_blank"><strong>JA </strong><span class=" primary--text"> Software</span> S.A.S</a>
                                </div>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-card>
            </v-layout>
        </main>
    </v-app>
</template>

<script>
import jwtDecode from "jwt-decode";

export default {
  name: "Auth",
  props: {
    loguear: Function,
    setTurnos: Function,
    setRegistro: Function,
  },
  data: () => ({
    credentials: {},
    valid: true,
    loading: false,
    empresas: [],
    entidad: null,
    ips: [],
    search:null,
    ancho: (typeof window !== 'undefined') ? window.outerWidth : 517,
    error:"",
    listaIPS:[],
    rules: {
      username: [
        v => !!v || "requiredo",
        v =>
        (v && v.length > 3) ||
        "El nombre de usuario debe tener almenos 3 letras"
        //v => /^[a-z0-9_]+$/.test(v) || "Caracteres no permitidos"
      ],
      entidad: [
        v => !!v || "requiredo",
        //v => /^[a-z0-9_]+$/.test(v) || "Caracteres no permitidos"
      ],
      password: [
        v => !!v || "requiredo",
        v => (v && v.length > 2) || "La contraseña debe tener almenos 4 digitos"
      ]
    }
  }),
  async created(){
    await this.$config.cargar();
    this.listaIPS = this.$config.listaIPS;
  },
  mounted: async function() {
    if (typeof window !== 'undefined') {
      document.querySelector('html').style.overflow = 'hidden';
      document.querySelector('body').style.overflow = 'hidden';
      document.querySelector('#app').style.backgroundImage = 'linear-gradient(to top, #000428, rgb(0 126 146))';
      document.querySelector('.v-application').style.backgroundImage = 'linear-gradient(to top, #000428, rgb(0 126 146))';
      if (this.$cookies.get("token") != null) {
        this.$router.push("/inicio");
      }
      window.onresize = () => {
        var widthBrowser = window.outerWidth;
        this.ancho = widthBrowser;
      };
    }
  },

  methods: {
    filtrarIps() {
      if (this.search && this.search.length > 4) {
            this.error ="";
            this.ips = this.listaIPS.filter(e => e.NOMBRE.toLowerCase().indexOf(this.search.toString().toLowerCase()) > -1)
      }else{
          this.ips = []
          this.error = "Escriba 4 letras como minimo"
      }
    },
    async login() {
      // checking if the input is valid
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$apiService.login(this.credentials).then(res => {
            const decoded = jwtDecode(res.token);
            if (decoded.status) {
              this.$apiService.setConfigApi( res.token);
             // rgb(242, 243, 248)
              document.querySelector('html').style.overflow = 'auto';
              document.querySelector('body').style.overflow = 'auto';
              document.querySelector('#app').style.backgroundImage = '';
              document.querySelector('.v-application').style.backgroundImage = '';
              this.$cookies.set("token", res.token);
              let ips = this.ips.find(e => e.VERSION == this.credentials.entidad);
              this.$cookies.set("ENTIDAD", this.credentials.entidad);
              this.$cookies.set("TIPO", ips.TIPO);
              this.$cookies.set("DEFAULT", ips.DEFAULT);
              this.$cookies.set("NOMBRE", ips.NOMBRE);
              this.$cookies.set("user", {
                id: decoded.id,
                username: decoded.username,
                auth_key: decoded.auth_key,
                email: decoded.email,
                role: decoded.role,
                persona: decoded.persona,
                empleado: decoded.empleado,
                empresa: decoded.empresa,
                nombre: decoded.nombre,
                surcursales: JSON.parse(decoded.sucursales),
                version: this.credentials.entidad
              });
              this.$socket.emit('addusuario', {
                user: {
                  id: decoded.id,
                  username: decoded.username,
                  auth_key: decoded.auth_key,
                  email: decoded.email,
                  role: decoded.role,
                  persona: decoded.persona,
                  empleado: decoded.empleado,
                  empresa: decoded.empresa,
                  nombre: decoded.nombre,
                  surcursales: JSON.parse(decoded.sucursales),
                  version: this.credentials.entidad
                },
                token: res.token
              });
              // (this.ancho > 517) ? this.$router.push("/inicio"): this.$router.push("/menu");
              if(decoded.username == 'turnos'){
                this.$router.push("/turnos")
              }else{
                this.$router.push("/inicio")
              }
            } else {
              this.loading = false;
              this.$swal.fire({
                title: "Error",
                text: "Usuario desactivado",
                showConfirmButton: false,
                showCloseButton: false,
                timer: 3000,
                icon: "warning",
                confirmButtonText: "Ok"
              });
            }

          })
          .catch(e => {
            this.loading = false;
            console.log(e);
            this.$swal.fire({
              title: "Error",
              text: "Nombre de usuario o password incorrecto",
              showConfirmButton: false,
              showCloseButton: false,
              timer: 3000,
              icon: "warning",
              confirmButtonText: "Ok"
            });
          });
      }
    }
  }
};

</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
}

.login-card {
  margin-top: 2% !important;
}

</style>
